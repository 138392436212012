import React from 'react';
import { useNavigate } from 'react-router-dom';

const Menu = () => {
  const navigate = useNavigate();

  const handleEditProductClick = () => {
    navigate('/editproduct');
  };

  const handleDeleteProductClick = () => {
    navigate('/deleteproduct');
  };

  const handleEditSlotClick = () => {
    navigate('/editslot');
  };

  const handleDeleteSlotClick = () => {
    navigate('/deleteslot');
  };

  const handleAddPost = () => {
    navigate('/addpost');
  };

  const handleDashboardClick = () => {
    navigate('/dashboard');
  };

  const handleAppointmentDashboardClick = () => {
    navigate('/appointmentsdashboard');
  };

  const handleAddProductClick = () => {
    navigate('/add-product');
  };

  const handleAddSlotsClick = () => {
    navigate('/add-appointment');
  };

  const handleProfileClick = () => {
    navigate('/profile');
  };

  const handleOrdersClick = () => {
    navigate('/myorders');
  };

  const handleAppointmentOrdersClick = () => {
    navigate('/myappointments');
  };

  const handleAddRemoveFeeClick = () => {
    navigate('/addremovefee');
  };

  const handleKeywords = () => {
    navigate('/addkeywords');
  };

  return (
    <div style={styles.container}>

      
      <h6 style={styles.title}>Menu & More</h6>

      {/* Add, Edit , Delete Producst buttons */}
      <h6 style={styles.heading}>Add & Delete Store Products</h6>
      <div style={styles.buttonContainer}>
      <button style={styles.button} onClick={handleAddProductClick} >Add Product</button>
        {/* <button style={styles.button} onClick={handleEditProductClick}>
          Edit Product
        </button> */}
        <button style={styles.button} onClick={handleDeleteProductClick}>
          Delete Product
        </button>
      </div>

{/* Add, Edit, Delete Slots Buttons */}
      {/* <h6 style={styles.heading}>Add, Edit and Delete Appointments / Slots</h6>
      <div style={styles.buttonContainer}>
      <button style={styles.button} onClick={handleAddSlotsClick} >Add Slots</button>
        <button style={styles.button} onClick={handleEditSlotClick}>
          Edit Slots
        </button>
        <button style={styles.button} onClick={handleDeleteSlotClick}>
          Delete Slots
        </button>
      </div> */}
      
      {/* <h6 style={styles.heading}>Add Posts</h6>
      <button style={styles.button} onClick={handleAddPost}>
          Add Post
        </button> */}

      {/* Set Default */}
      {/* <h6 style={styles.heading}>Set Default</h6>
      <div style={styles.buttonContainer}>
        <button style={styles.button}>Explore Store</button>
        <button style={{ ...styles.button, marginLeft: '10px' }}>Set Appointment</button>
      </div> */}


{/* DashBoard - store */}
      <h6 style={styles.heading}>Store Dashboard & More</h6>

      <div style={styles.buttonContainer}>
        <button style={styles.button} onClick={handleDashboardClick} >Store Dashboard</button>
      </div>

      <h6 style={styles.heading}>Add Keywords to your store</h6>

      <div style={styles.buttonContainer}>
        <button style={styles.button} onClick={handleKeywords} >Keywords</button>
      </div>


      {/* <div style={styles.buttonContainer}> */}
        {/* <button style={{ ...styles.button, marginLeft: '10px' }} onClick={handleProfileClick} >Profile</button> */}
        {/* <button style={{ ...styles.button, marginLeft: '10px' }} onClick={handleOrdersClick} >Store Orders</button>
        <button style={{ ...styles.button, marginLeft: '10px' }} onClick={handleAddRemoveFeeClick} >Add/Remove Store Fee</button>
      </div> */}

{/* Dashboard - appointment */}
      {/* <h6 style={styles.heading}>Appointment Dashboard & More</h6> */}

      {/* <div style={styles.buttonContainer}>
        <button style={styles.button} onClick={handleAppointmentDashboardClick} >Appointment Dashboard</button>
      </div> */}

      {/* <div style={styles.buttonContainer}>
        <button style={{ ...styles.button, marginLeft: '10px' }} onClick={handleProfileClick} >Profile</button>
        <button style={{ ...styles.button, marginLeft: '10px' }} onClick={handleAppointmentOrdersClick} >Appointment Orders</button>
        <button style={{ ...styles.button, marginLeft: '10px' }} onClick={handleAddRemoveFeeClick} >Add/Remove Store Fee</button>
      </div> */}

      {/* <h6 style={styles.heading}>More Options</h6>
      <button style={{ ...styles.button, marginLeft: '10px' }} onClick={handleProfileClick} >Profile</button> */}

    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    maxWidth: '800px',
    margin: '0 auto',
    marginTop: '60px',
  },
  title: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  heading: {
    textAlign: 'center',
    marginBottom: '15px',
    color: '#777',
    fontSize: '14px',
    fontWeight: 'normal',
  },
  buttonContainer: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  button: {
    padding: '5px 10px',
    backgroundColor: 'black',
    color: 'white',
    borderRadius: '100px',
    cursor: 'pointer',
    margin: '0 5px',
    textAlign: 'center',
    fontSize: '0.8rem',
    transition: 'background-color 0.2s ease',
    border: '2px solid white',
    boxSizing: 'border-box',
  },
};

export default Menu;
